import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonUnderline from "../components/Button/ButtonUnderline";
import Accordion from "../components/Accordion/Accordion";

const Page = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Certified Dog Training San Diego | Bella Woof"
            description="Create a deeper bond with your dog through our certified professional dog training in San Diego! Train at home or two convenient locations. Learn more!"
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <div className="container">
            <section className="pt-2 md:pt-20 pb-22 md:pb-28">
               <div className="grid md:grid-cols-2 gap-y-7 md:gap-x-16 lg:gap-x-28 items-center">
                  <div className="order-2 md:order-1">
                     <h1>San Diego Certified Dog Training</h1>
                     <p>
                        Create a deeper bond with your dog through our certified professional training! Our rewards-based approach is an effective
                        form of behavior modification. Practice in a safe and engaging environment whether you choose to train at home, at our La
                        Jolla location, or at the San Diego Humane Society.
                     </p>
                     <ButtonSolid href="tel:+1-619-237-0730" text="(619) 237-0730" className="gtm-phone-number" />
                  </div>
                  <div className="order-1 md:order-2">
                     <Img fluid={data.intro.childImageSharp.fluid} alt="San Diego Certified Dog Training" />
                  </div>
               </div>
            </section>

            <section className="mb-22 md:mb-28">
               <div className="grid md:grid-cols-12 gap-y-7 items-center ">
                  <div className="md:col-start-1 md:col-span-7">
                     <Img fluid={data.atHome.childImageSharp.fluid} alt="At-Home" className="rounded-3xl" />
                  </div>
                  <div className="md:col-end-13 md:col-span-5 md:pl-24">
                     <h2>At-Home</h2>
                     <p>
                        We offer customized training to meet your pet’s unique needs. This includes behavior assessment, a custom Behavior
                        Modification Plan, private sessions, and consultations.
                     </p>
                     <ButtonUnderline href="/at-home-dog-training/" text="Learn More" />
                  </div>
               </div>
            </section>

            <section className="mb-20 md:mb-32">
               <div className="grid md:grid-cols-12 gap-y-7 items-center">
                  <div className="order-2 md:order-1 md:col-start-1 md:col-span-5 md:pr-24">
                     <h2>Puppy Classes</h2>
                     <p>
                        Start ‘em young! Choose from the AKC Star Puppy Class at our La Jolla location, Puppy Preschool in partnership with the Humane
                        Society, or train at home.
                     </p>
                     <ButtonUnderline href="/san-diego-puppy-training-classes/" text="Learn More" />
                  </div>
                  <div className="order-1 md:order-2 md:col-end-13 md:col-span-7">
                     <Img fluid={data.puppyClasses.childImageSharp.fluid} alt="Puppy Classes" className="rounded-3xl" />
                  </div>
               </div>
            </section>

            <section className="mb-20 md:mb-32">
               <div className="grid md:grid-cols-12 gap-y-7 items-center">
                  <div className="md:col-start-1 md:col-span-7">
                     <Img fluid={data.adultDogs.childImageSharp.fluid} alt="Adult Dogs" className="rounded-3xl" />
                  </div>
                  <div className="md:col-end-13 md:col-span-5 md:pl-24">
                     <h2>Adult Dogs</h2>
                     <p>
                        There’s something for every pooch! Address immediate concerns with introductory classes or take your pet’s skills to the next
                        level with advanced training.
                     </p>
                     <ButtonUnderline href="/adult-dog-training-classes/" text="Learn More" />
                  </div>
               </div>
            </section>

            <section className="bg-secondary_light border border-solid border-secondary rounded-lg px-6 pt-14 md:pt-16 pb-12 md:pb-20 mb-20 md:mb-32">
               <div className="max-w-3xl mx-auto">
                  <img className={`mb-6 mx-auto`} src={data.info.publicURL} alt="Info" />
                  <header className="text-center mb-8 md:mb-12">
                     <h2 className="text-lg md:text-5xl ">Training Policies</h2>
                  </header>
                  <Accordion className="shadow-secondary" title="Classes & Training Refund Policy">
                     <p>
                        All Classes & Training Packages are non-refundable within 48 hrs of 1st scheduled class or training session. All Classes &
                        Training Packages are non-transferrable (dog or class). All Training Packages must be completed within 3 months of purchase.
                        No refunds or partial refunds, when class or training packages have commenced.
                     </p>
                  </Accordion>
                  <Accordion className="shadow-secondary" title="Individual Training Session Cancellation/No-Show Policy">
                     <p>
                        No cancellation fee, if 48 hour notice & rescheduled to new day. For "less than 48 hour notice of cancellation" or "no-show",
                        at our discretion a $100 fee may apply to book a new training appointment.
                     </p>
                  </Accordion>
                  <Accordion className="shadow-secondary" title="Training Class Policy">
                     <p>
                        No make-up classes. No transfers. At our discretion upon notification, a 15 minute make-up session may be available prior to
                        the next week's scheduled class.
                     </p>
                  </Accordion>
               </div>
            </section>
         </div>
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/Training_FB.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Training_Twitter.jpg" }) {
         publicURL
      }
      intro: file(relativePath: { eq: "training/1.0-Intro_Desktop.png" }) {
         childImageSharp {
            fluid(maxWidth: 1256, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      atHome: file(relativePath: { eq: "training/At-Home.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 1392, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      puppyClasses: file(relativePath: { eq: "training/Puppy-Classes.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 1392, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      adultDogs: file(relativePath: { eq: "training/Adult-Dogs.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 1392, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      info: file(relativePath: { eq: "global/info.svg" }) {
         publicURL
      }
   }
`;

export default Page;
